import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GifsService {

  constructor(private http: HttpClient) {
    console.log('GifsService constructor()');
  }

  public get() {
    const url = 'https://api.giphy.com/v1/gifs/search?api_key=OAUXNRjbp4RHkFXVDoPU24ShCimk4ytk&q=pmfafuk&limit=50&offset=0&rating=g&lang=en';
    return this.http.get<any>(url);
  }
}
