import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ReactiveFormsModule} from '@angular/forms';
import {QuizComponent} from './quiz/quiz.component';
import {GifsComponent} from './gifs/gifs.component';
import {TranslateComponent} from './translate/translate.component';
import {QuestionComponent} from './quiz/question/question.component';
import {AnswerComponent} from './quiz/question/answer/answer.component';
import {GifComponent} from './gifs/gif/gif.component';
import {AppService} from './app.service';
import {ModalModule} from './modal';
import {TextComponent} from './text/text.component';
import {GraphQLModule} from './graphql.module';

@NgModule({
  declarations: [AppComponent, DashboardComponent, TextComponent, QuizComponent, QuestionComponent, AnswerComponent, GifsComponent, GifComponent, TranslateComponent],
  entryComponents: [],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, ReactiveFormsModule, ModalModule, GraphQLModule],
  providers: [AppService],
  bootstrap: [AppComponent],
})
export class AppModule {
}
