import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Plugins, registerWebPlugin} from '@capacitor/core';
// eslint-disable-next-line @typescript-eslint/naming-convention
const {Clipboard, Toast} = Plugins;
import {FileSharer} from '@byteowls/capacitor-filesharer';
import {AppService} from '../../app.service';


@Component({
  selector: 'app-gif',
  templateUrl: './gif.component.html',
  styleUrls: ['./gif.component.scss'],
})
export class GifComponent implements OnInit {
  @Input() gif: any;

  constructor(
    private http: HttpClient,
    public appService: AppService
  ) {
  }

  ngOnInit() {
    registerWebPlugin(FileSharer);
  }

  public shareFileSharer(gif) {
    console.log('gif', gif);
    const url = gif.images.original.url;
    this.http.get(url, { responseType: 'blob' })
      .subscribe(blob => {
        const reader = this.getFileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = (event: any) => {
          const filename = gif.slug + '.' + gif.type;
          const base64Data = reader.result.toString().split(',')[1];
          const contentType = blob.type;
          Plugins.FileSharer.share({
            filename,
            base64Data,
            contentType,
          }).then(success => {
            console.log('File sharing success');
          }).catch(error => {
            console.error('File sharing failed');
            console.error(error);
          });
        };
        reader.onerror = (event: any) => {
          console.log('File could not be read');
          return false;
        };
      });
  }

  public clipboardCopy(gif) {
    const url = gif.images.original.url;
    Clipboard.write({
      image: url
    })
    .then(result => {
      Toast.show({
        text: 'Do kinst it gifke no yn oare apps plakke.',
        duration: 'long',
        position: 'top'
      });
    })
    .catch(error => {
      console.log(error);
    });
  }

  private getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)['__zone_symbol__originalInstance'];
    return zoneOriginalInstance || fileReader;
  }

}
