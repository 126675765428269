import {Component, OnInit} from '@angular/core';
import {AppService} from './app.service';
import {SplashScreen, registerWebPlugin} from '@capacitor/core';
import {FileSharer} from '@byteowls/capacitor-filesharer';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    public appService: AppService
  ) {
    SplashScreen.hide().then();
  }

  ngOnInit() {
    document.body.classList.add('platform-' + this.appService.platform);
    console.log('Register custom capacitor plugins');
    registerWebPlugin(FileSharer);
  }
}

