import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {Apollo, gql} from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class TextService {

  constructor(
    private apollo: Apollo
  ) {
    console.info('TextService constructor()');
  }

  public getTranslations(inputFry: boolean, text: string): Observable<any> {
    console.log('TextService getTranslations', text);
    if (inputFry) {
      return this.apollo
        .query({
          variables: {text: text},
          query: gql`
            query translate($text: String!) {
              translatetext(
                text: $text
                lang: fry
              ) {translation}
            }
          `,
        });
    } else {
      return this.apollo
        .query({
          variables: {text: text},
          query: gql`
            query translate($text: String!) {
              translatetext(
                text: $text
                lang: nl
              ) {translation}
            }
          `,
        });
    }
  }
}
