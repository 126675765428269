import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuizService {
  public offLine = false;

  constructor(private http: HttpClient) {
    console.log('QuizService constructor()');
  }

  public get() {
    if (this.offLine) {
      return of({
        success: true,
        data: [{
          index: 0,
          title: 'Geef of net geef: \'Us heit h\u00e2ldt fan krantl\u00eazen.\'',
          answers: [{index: 0, title: 'geef'}, {index: 1, title: 'net geef'}]
        }],
        survey_id: '8816'
      });
    }
    const url = 'https://learmarfrysk.nl/api/get_survey.php';
    return this.http.get<any>(url);
  }

  public post(httpParams: HttpParams) {
    if (this.offLine) {
      return of({
        success: true, data: {
          questions: [{
            index: 0,
            title: 'Geef of net geef: \'Us heit h\u00e2ldt fan krantl\u00eazen.\'',
            is_correct: true,
            given_answer: '1',
            feedback: '',
            answers: [{index: 0, title: 'geef', answered: false, is_correct: '1', feedback: ''}, {
              index: 1,
              title: 'net geef',
              answered: true,
              is_correct: '0',
              feedback: ''
            }]
          }, {
            index: 1,
            title: 'Hy sjocht ek graach .....',
            is_correct: true,
            given_answer: '1',
            feedback: '',
            answers: [{
              index: 0,
              title: 'reportaasjes',
              answered: false,
              is_correct: '0',
              feedback: 'teportaazjes - Ned. wurden mei -ge krije yn it Frysk -zje'
            }, {
              index: 1,
              title: 'reportaazjes',
              answered: true,
              is_correct: '1',
              feedback: 'Ned. wurden mei -ge krije yn it Frysk -zje'
            }, {
              index: 2,
              title: 'reportaezjes',
              answered: false,
              is_correct: '0',
              feedback: 'reportaazjes - Ned. wurden mei -ge krije yn it Frysk -zje, ae is \u00e2lde stavering'
            }],
            feeback: null
          }, {
            index: 2,
            title: 'Wat is de goede skriuwwize?',
            is_correct: true,
            given_answer: '1',
            feedback: '',
            answers: [{
              index: 0,
              title: 'sjoernalist',
              answered: false,
              is_correct: '1',
              feedback: 'foar de r: twal\u00fbd, wurdt stavere mei oe'
            }, {
              index: 1,
              title: 'sjournalist',
              answered: true,
              is_correct: '0',
              feedback: 'sjoernalist - foar de r: twal\u00fbd, wurdt stavere mei oe'
            }, {
              index: 2,
              title: 'sj\u00fbrnalist',
              answered: false,
              is_correct: '0',
              feedback: 'sjoernalist - foar de r: twal\u00fbd, wurdt stavere mei oe'
            }]
          }, {
            index: 3,
            title: 'Wat is Frysk foar \'het gaat als een lopend vuurtje\'?',
            is_correct: true,
            given_answer: '1',
            feedback: '',
            answers: [{
              index: 0,
              title: 'It giet as diggelfjoer',
              answered: false,
              is_correct: '1',
              feedback: ''
            }, {index: 1, title: 'It nijs fljocht', answered: true, is_correct: '0', feedback: ''}, {
              index: 2,
              title: 'It giet as in rinnend fjurke',
              answered: false,
              is_correct: '0',
              feedback: ''
            }]
          }], amount_correct: 1, amount_incorrect: 3, score: 25
        }
      });
    }
    const url = 'https://learmarfrysk.nl/api/post_survey.php';
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(
      url,
      httpParams.toString(),
      { headers }
    );
  }
}
