import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Apollo, gql} from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  constructor(
    private apollo: Apollo
  ) {
    console.info('TranslateService constructor()');
  }

  public getAutoComplete(language: string, word: string): Observable<any> {
    console.log('DashboardService getAutoCompleteDutch', word);
    if (language === 'nl') {
      return this.apollo
        .query({
          variables: {word: word},
          query: gql`
          query lemmasearch($word: String!) {
            lemmasearch (offset: 0 max: 10 searchterm: $word lang: nl)
            { offset total max lemmas {form grammar translations {form}}}
          }
        `,
        });
    } else {
      return this.apollo
        .query({
          variables: {word: word},
          query: gql`
          query lemmasearch($word: String!) {
            lemmasearch (offset: 0 max: 10 searchterm: $word lang: fry)
            { offset total max lemmas {form grammar translations {form}}}
          }
        `,
        });
    }
  }
}
