import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Apollo, gql} from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private apollo: Apollo
  ) {
    console.info('DashboardService constructor()');
  }

  public getAutoCompleteDutch(word: string): Observable<any> {
    const preffix = (word.length > 2) ? '*' : '';
    return this.apollo
      .query({
        variables: {word: word + preffix},
        query: gql`
          query lemmasearch($word: String!) {
            lemmasearch (offset: 0 max: 20 searchterm: $word lang: nl)
            { offset total max lemmas {form grammar translations {form}}}
          }
        `,
      });
  }

  public getAutoCompleteFrisian(word: string): Observable<any> {
    const preffix = (word.length > 2) ? '*' : '';
    return this.apollo
      .query({
        variables: {word: word + preffix},
        query: gql`
          query lemmasearch($word: String!) {
            lemmasearch (offset: 0 max: 20 searchterm: $word lang: fry)
            { offset total max lemmas {form grammar translations {form}}}
          }
        `,
      });
  }
}
