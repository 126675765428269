import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from './translate.service';
import {Plugins} from '@capacitor/core';
import {ModalService} from '../modal';

const {Clipboard, Toast} = Plugins;

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss'],
})
export class TranslateComponent implements OnInit, OnChanges {
  @Input() activeView: string;
  @Input() selectedLanguage: any;
  @Input() selectedValue: any;
  @Output() setActiveViewOutput = new EventEmitter();
  public translateResults = [];
  public selectedLanguageIcon: string | null;
  public loaderActive = true;
  public modalTranslateTitle = '';
  public modalTranslateBody = '';

  constructor(
    public translateService: TranslateService,
    public modalService: ModalService,
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('changes.activeView.currentValue', changes.activeView.currentValue);
    console.log('translateResults', this.translateResults);
    if (changes.activeView.currentValue === 'translate') {
      this.loaderActive = true;
      this.selectedLanguageIcon = (this.selectedLanguage === 'nl') ? 'NL' : 'FR';
      this.translateService.getAutoComplete(this.selectedLanguage, this.selectedValue).subscribe(
        results => {
          console.log(results);
          let lemmas = results.data.lemmasearch?.lemmas;
          if (Array.isArray(lemmas)) {
            console.log('lemmas', lemmas);
          } else {
            lemmas = [];
          }
          results = [];
          lemmas.forEach((lemma) => {
            console.log('translateResults', lemma);
            if ((Array.isArray(lemma.translations))) {
              lemma.translations.forEach(translation => {
                if (!results.includes(translation.form.toLowerCase())) {
                  results.push(translation.form.toLowerCase());
                }
              });
            }
          });
          this.translateResults = results;
          console.log('translateResults', this.translateResults);
          this.loaderActive = false;
        },
        error => {
          this.modalTranslateTitle = 'Verbinding maken mislukt';
          this.modalTranslateBody = error.message;
          this.modalService.open('modalTranslate');
          console.log('translateService.getTranslations', error);
          this.loaderActive = false;
        }
      );
    }
  }

  public setActiveView(view) {
    this.setActiveViewOutput.emit(view);
    setTimeout(() => {
      this.translateResults = [];
      this.selectedLanguageIcon = null;
    }, 200);
  }

  copyClipboard(translation) {
    Clipboard.write({
      string: translation
    }).then((_) => {
      Toast.show({
        text: 'Do kinst it wurd \'' + translation + '\' no yn oare apps plakke.',
        duration: 'long',
        position: 'top'
      });
    });
  }
}
