import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Input, OnChanges,
  OnInit,
  Output,
  QueryList, Renderer2, SimpleChanges,
  ViewChildren
} from '@angular/core';
import {QUIZ} from '../data/quiz';
import {Question, Answer} from './quiz.interface';
import {QuizService} from './quiz.service';
import {HttpParams} from '@angular/common/http';
import {gsap, Power3} from 'gsap';
import {ModalService} from '../modal';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() activeView: string;
  @Output() setActiveViewOutput = new EventEmitter();
  @ViewChildren('refResultInfo') refResultInfo: QueryList<ElementRef>;
  @ViewChildren('refSliderItem') refSliderItem: QueryList<ElementRef>;
  public quiz: Question[];
  public quizResults: Question[];
  public currentQuestionId: number;
  public arrowRightClass: any;
  public arrowLeftClass: any;
  public isSliderItemResultActive: boolean;
  public result: any;
  public loaderActive = false;
  public resultLoaderActive = false;
  public modalQuizTitle = '';
  public modalQuizBody = '';

  constructor(
    private renderer: Renderer2,
    public quizService: QuizService,
    public modalService: ModalService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('changes.activeView.currentValue', changes.activeView.currentValue);
    if (changes.activeView.currentValue === 'quiz') {
      this.loaderActive = true;
      this.quizService.get().subscribe(
        data => {
        console.log('quizService', data);
        setTimeout(() => {
          this.setQuiz(data);
          this.loaderActive = false;
        }, 0);
      },
        error => {
          this.modalQuizTitle = error.name;
          this.modalQuizBody = error.message;
          this.modalService.open('modalQuiz');
          console.log('quizService.get', error);
          this.loaderActive = false;
        }
      );
    }
  }

  ngAfterViewInit() {

  }

  public setQuiz(data): void {
    this.currentQuestionId = 0;
    this.isSliderItemResultActive = false;
    this.quiz = data.data.map((question) => ({
      id: question.index,
      nr: question.index + 1,
      content: question.title,
      answers: question.answers.map((answer) => ({
        id: answer.index,
        content: answer.title
      } as Answer)),
      answer: null
    } as Question));
    this.setArrowLeftClass();
    this.setArrowRightClass();
    setTimeout(() => {
      const refSliderItemNew = this.refSliderItem.filter((element, index) => index === this.currentQuestionId)[0];
      this.renderer.removeClass(refSliderItemNew.nativeElement, 'slider__item--right');
    }, 0);
  }

  public resetQuiz(): void {
    this.currentQuestionId = null;
    this.isSliderItemResultActive = false;
    this.quiz = null;
    this.quizResults = null;
    this.arrowLeftClass = {};
    this.arrowRightClass = {};
  }

  public isCurrentQuestion(question) {
    return this.currentQuestionId === question.id;
  }

  public getCurrentQuestionClass(question): string {
    if (this.currentQuestionId < question.id) {
      return 'slider__item--right';
    }
    if (this.currentQuestionId < question.id) {
      return 'slider__item--left';
    }
    return '';
  }

  public setActiveView(view) {
    this.resetQuiz();
    this.setActiveViewOutput.emit(view);
  }

  public getQuestion(id: number): Question {
    const questionTemp = QUIZ.filter(question => question.id === id);
    console.log('getQuestion(id)', questionTemp[0]);
    return questionTemp[0];
  }

  public hasPrev() {
    console.log('hasPrev()', this.currentQuestionId);
    return this.currentQuestionId > 0;
  }

  public setPrevQuestion(): void {
    if (!this.hasPrev()) {
      return;
    }
    const refSliderItemOld = this.refSliderItem.filter((element, index) => index === this.currentQuestionId)[0];
    this.renderer.addClass(refSliderItemOld.nativeElement, 'slider__item--right');
    const id = this.currentQuestionId - 1;
    this.currentQuestionId = id;
    const refSliderItemNew = this.refSliderItem.filter((element, index) => index === this.currentQuestionId)[0];
    this.renderer.removeClass(refSliderItemNew.nativeElement, 'slider__item--left');
    this.setArrowLeftClass();
    this.setArrowRightClass();
  }

  public hasNext() {
    return this.currentQuestionId < this.quiz.length + 1;
  }

  public allowedNext() {
    const currentQuestion = this.quiz.filter(question => question.id === this.currentQuestionId);
    return (currentQuestion.length) ? currentQuestion[0].answer : false;
  }

  public setNextQuestion(): void {
    if (!this.hasNext()) {
      return;
    }
    if (this.allowedNext() === null) {
      return;
    }
    if (this.currentQuestionId + 1 === this.quiz.length) {
      this.isSliderItemResultActive = true;
      this.resultLoaderActive = true;
      let httpParams = new HttpParams();
      this.quiz.forEach((question) => {
        httpParams = httpParams.append('answer[' + question.id + ']', question.answer.id.toString());
      });
      this.quizService.post(httpParams).subscribe(
        data => {
        setTimeout(() => {
          this.quizResults = data.data.questions.map((question) => ({
            id: question.index,
            nr: question.index + 1,
            content: question.title,
            answers: question.answers.map((answer) => ({
              id: answer.index,
              content: answer.title,
              correct: answer.is_correct === '1'
            } as Answer)),
            answer: question.answers.filter(answer => answer.answered).map((a) => ({
              id: a.index,
              content: a.title,
              correct: a.is_correct === '1',
            }))[0],
            feedback: question.answers.filter(answer => answer.answered)[0].feedback
          } as Question));
          console.log(this.quizResults);
          this.resultLoaderActive = false;
        }, 0);
      },
        error => {
          this.modalQuizTitle = error.name;
          this.modalQuizBody = error.message;
          this.modalService.open('modalQuiz');
          console.log('quizService.post', error);
          this.resultLoaderActive = false;
        }
      );
    }
    const refSliderItemOld = this.refSliderItem.filter((element, index) => index === this.currentQuestionId)[0];
    this.renderer.addClass(refSliderItemOld.nativeElement, 'slider__item--left');
    const id = this.currentQuestionId + 1;
    this.currentQuestionId = id;
    if (this.refSliderItem.length > this.currentQuestionId) {
      const refSliderItemNew = this.refSliderItem.filter((element, index) => index === this.currentQuestionId)[0];
      console.log('refSliderItemNew', refSliderItemNew);
      this.renderer.removeClass(refSliderItemNew.nativeElement, 'slider__item--right');
    }
    this.setArrowLeftClass();
    this.setArrowRightClass();
  }

  public setArrowLeftClass() {
    if (this.hasPrev()) {
      this.arrowLeftClass = {'bg-color--yellow2': true};
    } else {
      this.arrowLeftClass = {'icon--hide': true};
    }
  }

  public setArrowRightClass() {
    if (this.allowedNext()) {
      this.arrowRightClass = {'bg-color--yellow2': true};
    } else {
      this.arrowRightClass = {'icon--hide': true};
    }
  }

  public changedQuestion(changedQuestion) {
    console.log('changedQuestion', changedQuestion);
    this.quiz = this.quiz.map(question => {
      if (question.id === changedQuestion.id) {
        return changedQuestion;
      }
      return question;
    });
    console.log('changedQuiz', this.quiz);
    this.setArrowLeftClass();
    this.setArrowRightClass();
  }

  public resultAnswerClass(question, answer) {
    //check if answer is correct
    if (answer.correct) {
      return {fas: true, 'fa-check-circle': true, 'color--green8': true};
    }
    //check if answer is given
    if (answer.id === question.answer?.id) {
      return {fas: true, 'fa-check-circle': true, 'color--orange3': true};
    }
    return {far: true, 'fa-circle': true, 'color--green4': true};
  }

  public resultSquareSrc(question) {
    if (question.answer?.correct) {
      return '/assets/images/blinder.png';
    }
    if (question.answer?.correct === false) {
      return '/assets/images/blunder.png';
    }
    return '';
  }

  public resultAnswersCorrect(quiz) {
    return quiz.filter(question => question.answer?.correct).length;
  }

  public resultAnswersWrong(quiz) {
    return quiz.filter(question => question.answer?.correct === false).length;
  }

  public isActiveSlider(question) {
    return this.currentQuestionId === question.id;
  }

  public toggleResultInfo(i) {
    const element = this.refResultInfo.toArray()[i];
    if (element.nativeElement.classList.contains('result__info--hide')) {
      this.renderer.removeClass(element.nativeElement, 'result__info--hide');
    } else {
      this.renderer.addClass(element.nativeElement, 'result__info--hide');
    }
  }
}
