import {Injectable} from '@angular/core';
import {Capacitor, Plugins} from '@capacitor/core';
import {BehaviorSubject, Observable} from 'rxjs';
// eslint-disable-next-line @typescript-eslint/naming-convention
const {Network} = Plugins;
import {NetworkStatus} from './app.interface';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AppService {
  platform: string;
  isNative: boolean;
  inputFry: boolean;
  innerHeight: number;
  innerWidth: number;
  public iosSafeAreaInsetTopHeight: number;
  public networkStatusSubject: BehaviorSubject<NetworkStatus> = new BehaviorSubject<NetworkStatus>({} as NetworkStatus);
  public networkStatusObservable: Observable<NetworkStatus> = this.networkStatusSubject.asObservable().pipe(filter(networkStatus => Object.keys(networkStatus).length > 0));

  constructor() {
    console.log('AppService constructor()');
    this.setPlatformVars();
    this.getInputFry();
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
    Network.addListener('networkStatusChange', (networkStatus) => {
      this.networkStatusSubject.next(networkStatus);
    });
    Network.getStatus().then(networkStatus => {
      this.networkStatusSubject.next(networkStatus);
    });
    window.addEventListener('resize', () => {
      console.log('resize');
      this.innerHeight = window.innerHeight;
      this.innerWidth = window.innerWidth;
    });
  }

  setPlatformVars(): void {
    this.platform = Capacitor.getPlatform();
    this.isNative = Capacitor.isNative;
    console.log('platform:' + this.platform);
    console.log('isNative:' + this.isNative);
  }

  getInputFry(): void {
    const inputFry = localStorage.getItem('oersetter-inputFry');
    console.log('inputFry', inputFry);
    this.inputFry = inputFry ? inputFry === 'true' ? true : false : false;
  }

  setInputFry(bln: boolean): void {
    console.log('setInputFry', bln);
    this.inputFry = bln;
    localStorage.setItem('oersetter-inputFry', bln.toString());
  }

  getViewPortSizes() {
    return {
      height: this.innerHeight + 'px',
      width: this.innerWidth + 'px'
    };
  }
}
