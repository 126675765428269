import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DashboardService} from './dashboard.service';
import {ModalService} from '../modal';
import {NetworkStatus} from '../app.interface';
import {AppService} from '../app.service';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @ViewChild('iosSafeAreaInsetTop') iosSafeAreaInsetTop: ElementRef;
  public activeView = 'dashboard';
  public inputDutch = new FormControl();
  public inputFrisian = new FormControl();
  public inputDutchValue = '';
  public inputFrisianValue = '';
  public resultsInputDutch = [];
  public resultsInputFrisian = [];
  public resultsInputDutchNotFound = false;
  public resultsInputFrisianNotFound = false;
  public selectedLanguage = '';
  public selectedValue = '';
  public inputDutchLoader = false;
  public inputDutchClearer = false;
  public inputFrisianLoader = false;
  public inputFrisianClearer = false;
  public modalDashboardTitle = '';
  public modalDashboardBody = '';
  public inputFrisianHasFocus = false;
  public inputDutchHasFocus = false;
  public iosSafeAreaInsetTopHeight: number;

  constructor(
    private elem: ElementRef,
    public dashboardService: DashboardService,
    public appService: AppService,
    public modalService: ModalService
  ) {
    console.log('DashboardComponent constructor()');
    console.log('activeView', this.activeView);
  }

  @HostListener('touchstart', ['$event.target'])
  onTouchStart(target) {
    if (target.tagName.toUpperCase() === 'INPUT') {
      return;
    }
    console.log('touchstart', target);
    const elements = this.elem.nativeElement.querySelectorAll('input');
    elements.forEach(element => {
      element.blur();
    });
  }

  ngOnInit() {
    this.inputDutch.valueChanges.pipe(debounceTime(250)).subscribe(value => {
      // this.clearInputFrisian();
      this.inputDutchValue = value;
      if (value.length) {
        this.inputDutchClearer = true;
        if (value.length < 2) {
          return;
        }
        this.inputDutchLoader = true;
        this.dashboardService.getAutoCompleteDutch(value.toLowerCase()).subscribe(
          results => {
            console.log('results', results);
            let autoComplete = results.data.lemmasearch?.lemmas;
            if (Array.isArray(autoComplete)) {
              autoComplete = autoComplete.map((lemma) => {
                if (lemma.grammar?.length) {
                  // return lemma.form + ' (' + lemma.grammar[0] + ')';
                }
                return lemma.form;
              }).filter((v, i, a) => a.indexOf(v) === i);
              console.log('autoComplete', autoComplete);
              // autoComplete.sort();
              autoComplete = autoComplete.slice(0, 6).map(e => e.toLowerCase());
            } else {
              autoComplete = [];
            }
            this.resultsInputDutchNotFound = autoComplete.length === 0 ? true : false;
            this.resultsInputDutch = autoComplete.map(s => s.replace(value, '<span>' + value + '</span>'));
            this.inputDutchLoader = false;
          },
          error => {
            this.modalDashboardTitle = error.name;
            this.modalDashboardBody = error.message;
            this.modalService.open('modalDashboard');
            console.log('dashboardService.getAutoComplete', error);
            this.inputDutchLoader = false;
          }
        );
      } else {
        this.resultsInputDutch = [];
        this.inputDutchClearer = false;
      }
    });
    this.inputFrisian.valueChanges.pipe(debounceTime(250)).subscribe(value => {
      // this.clearInputDutch();
      this.inputFrisianValue = value;
      if (value.length) {
        this.inputFrisianClearer = true;
        if (value.length < 2) {
          return;
        }
        this.inputFrisianLoader = true;
        this.dashboardService.getAutoCompleteFrisian(value.toLowerCase()).subscribe(
          results => {
            console.log(results);
            let autoComplete = results.data.lemmasearch?.lemmas;
            if (Array.isArray(autoComplete)) {
              autoComplete = autoComplete.map((lemma) => {
                if (lemma.grammar?.length) {
                  // return lemma.form + ' (' + lemma.grammar[0] + ')';
                }
                return lemma.form;
              }).filter((v, i, a) => a.indexOf(v) === i);
              console.log(autoComplete);
              // autoComplete.sort();
              autoComplete = autoComplete.slice(0, 6).map(e => e.toLowerCase());
            } else {
              autoComplete = [];
            }
            this.resultsInputFrisianNotFound = autoComplete.length === 0 ? true : false;
            this.resultsInputFrisian = autoComplete.map(s => s.replace(value, '<span>' + value + '</span>'));
            this.inputFrisianLoader = false;
          },
          error => {
            this.modalDashboardTitle = error.name;
            this.modalDashboardBody = error.message;
            this.modalService.open('modalDashboard');
            console.log('dashboardService.getAutoComplete', error);
            this.inputFrisianLoader = false;
          }
        );
      } else {
        this.resultsInputFrisian = [];
        this.inputFrisianClearer = false;
      }
    });
  }

  ngAfterViewInit() {
    this.appService.networkStatusObservable.subscribe((networkStatus: NetworkStatus) => {
      console.log('networkStatus', networkStatus);
      if (!networkStatus.connected) {
        this.modalDashboardTitle = 'Verbinding maken mislukt';
        this.modalDashboardBody = 'Er is een fout opgetreden, controleer je internetverbinding en probeer het dan opnieuw.';
        this.modalService.open('modalDashboard');
      }
    });
    setTimeout(() => {
      console.log('this.iosSafeAreaInsetTopHeight', this.iosSafeAreaInsetTop.nativeElement.offsetHeight);
      this.iosSafeAreaInsetTopHeight = this.iosSafeAreaInsetTop.nativeElement.offsetHeight;
    }, 250);
  }

  public setActiveView(view: string): void {
    this.activeView = view;
  }

  public activateView(view: string): void {
    this.activeView = view;
    console.log(this.activeView);
    if (view === 'input') {
    }
  }

  public hasResultsInputDutch() {
    return this.resultsInputDutchNotFound ? true : this.resultsInputDutch.length;
  }

  public hasResultsInputFrisian() {
    return this.resultsInputFrisianNotFound ? true : this.resultsInputFrisian.length;
  }

  public selectResultsInput(language: string, item: string) {
    this.selectedLanguage = language;
    this.selectedValue = item.replace('<span>', '').replace('</span>', '');
    this.resultsInputDutch = [];
    this.resultsInputFrisian = [];
    this.inputDutch.setValue('');
    this.inputFrisian.setValue('');
    this.activeView = 'translate';
  }

  public inputDutchFocus() {
    this.inputDutchHasFocus = true;
    this.clearInputFrisian();
  }

  public inputDutchBlur() {
    this.inputDutchHasFocus = false;
  }

  public clearInputDutch() {
    this.inputDutch.setValue('');
    this.resultsInputDutchNotFound = false;
  }

  public inputFrisianFocus() {
    this.inputFrisianHasFocus = true;
    this.clearInputDutch();
  }

  public inputFrisianBlur() {
    this.inputFrisianHasFocus = false;
  }

  public clearInputFrisian() {
    this.inputFrisian.setValue('');
    this.resultsInputFrisianNotFound = false;
  }
}
