import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Question} from '../quiz.interface';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit, OnChanges {
  @Input() question: Question;
  @Output() changedQuestionOutput = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    console.log('QuestionComponent ngOnChanges()');
  }

  public givenAnswer(givenAnswer) {
    const changedQuestion: Question = {
      id: this.question.id,
      nr: this.question.id + 1,
      content: this.question.content,
      answers: this.question.answers,
      answer: givenAnswer
    };
    this.changedQuestionOutput.emit(changedQuestion);
  }
}
