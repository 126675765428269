import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnChanges,
  OnInit,
  Output, Renderer2, SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {TextService} from './text.service';
import {FormControl} from '@angular/forms';
import {gsap} from 'gsap';
import {Plugins} from '@capacitor/core';
import {AppService} from '../app.service';
import {ModalService} from '../modal';
// eslint-disable-next-line @typescript-eslint/naming-convention
const {Clipboard, Toast} = Plugins;

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent implements OnInit, OnChanges {
  @Input() activeView: string;
  @Output() setActiveViewOutput = new EventEmitter();
  @ViewChild('switch') switch: ElementRef;
  @ViewChild('layer') layer: ElementRef;
  @ViewChild('layerBefore') layerBefore: ElementRef;
  @ViewChild('layerTextArea') layerTextArea: ElementRef;
  @ViewChild('layerResult') layerResult: ElementRef;
  @ViewChild('actionTranslate') actionTranslate: ElementRef;
  @ViewChild('actionTranslateLoader') actionTranslateLoader: ElementRef;
  public inputFry = true;
  public input = new FormControl();
  public inputValue = '';
  public inputLoader = false;
  public inputClearer = false;
  public resultsInput: string;
  public translated = false;
  public rotateY = 180;
  public innerHeight: number;
  public innerWidth: number;
  public style: any;
  public isInputFocused = false;
  public scrollBottom = true;
  public modalTextTitle = '';
  public modalTextBody = '';
  private inverseColor = '#50a2a4';
  private inverse = '#cee7e2';
  private white = '#ffffff';
  private black = '#333333';

  constructor(
    private elem: ElementRef,
    public textService: TextService,
    public appService: AppService,
    public modalService: ModalService,
    private renderer: Renderer2,
  ) {
  }

  @HostListener('window:resize', ['$event']) onResize() {
    this.setScrollBottom();
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll() {
    this.setScrollBottom();
  }

  @HostListener('touchstart', ['$event.target'])
  onTouchStart(target) {
    if (target.tagName.toUpperCase() === 'TEXTAREA') {
      return;
    }
    console.log('touchstart', target);
    const elements = this.elem.nativeElement.querySelectorAll('textarea');
    setTimeout(() => {
      elements.forEach(element => {
        element.blur();
      });
    }, 250); //submit() click fix hack
  }

  ngOnInit() {
    this.input.valueChanges.subscribe(value => {
      this.inputValue = value;
      if (value.length) {
        this.inputClearer = true;
        const tl = gsap.timeline();
        tl.to(this.actionTranslate.nativeElement, {duration: 0, display: 'block'}, '');
        tl.to(this.actionTranslate.nativeElement, {duration: .2, opacity: 1}, '<');
      } else {
        this.inputClearer = false;
        const tl = gsap.timeline();
        tl.to(this.actionTranslate.nativeElement, {duration: .2, opacity: 0}, '');
        tl.to(this.actionTranslate.nativeElement, {display: 'none'}, '<');
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('changes.activeView.isFirstChange()', changes.activeView.isFirstChange());
    console.log('changes.activeView.currentValue', changes.activeView.currentValue);
    if (changes.activeView.currentValue === 'text') {
      setTimeout(() => {
        this.setFocus();
      }, 500);
    }
  }

  private setScrollBottom() {
    // In chrome and some browser scroll is given to body tag
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    // console.log('scrollTop', scrollTop);
    // console.log('window.innerHeight', window.innerHeight);
    // console.log('document.documentElement.scrollHeight', document.documentElement.scrollHeight);
    if (window.innerHeight + scrollTop >= document.documentElement.scrollHeight) {
      this.scrollBottom = true;
    } else {
      this.scrollBottom = false;
    }
  }

  public autocompleteFocus() {
    this.isInputFocused = true;
  }

  public autocompleteBlur() {
    this.isInputFocused = false;
  }

  public submit() {
    if (this.inputValue.length < 1) {
      return;
    }
    this.translated = true;
    const d = 0.5;
    const tl = gsap.timeline();
    tl.to(this.layerResult.nativeElement, {duration: d, top: (27 + 100 + 27) + 'px'}, '');
    tl.to(this.actionTranslate.nativeElement, {duration: 0, opacity: 0}, '<');
    tl.to(this.actionTranslate.nativeElement, {display: 'none'}, '<');
    tl.to(this.layer.nativeElement, {duration: d, backgroundColor: this.inverse, color: this.inverseColor}, '<');
    tl.to(this.layerTextArea.nativeElement, {duration: d, height: '100px'}, '<');
    tl.to(this.switch.nativeElement, {duration: d, height: '0', paddingBottom: 0}, '<');
    this.inputLoader = true;
    this.textService.getTranslations(this.appService.inputFry, this.inputValue.toLowerCase()).subscribe(
      data => {
        setTimeout(() => {
          console.log('translation', data);
          let results = data.data.translatetext?.translation ?? '';
          this.resultsInput = results;
          this.inputLoader = false;
        }, 0);
      },
      error => {
        this.modalTextTitle = 'Verbinding maken mislukt';
        this.modalTextBody = error.message;
        this.modalService.open('modalText');
        console.log('textService.getTranslations', error);
        this.resultsInput = '';
        this.inputLoader = false;
      }
    );
  }

  public clearInput() {
    this.input.setValue('');
    this.setFocus();
  }

  public initTranslate() {
    this.input.setValue('');
    this.setInputState(true);
  }

  public setFocus() {
    this.layerTextArea.nativeElement.focus();
    if (this.inputValue.length > 1) {
      const tl = gsap.timeline();
      tl.to(this.actionTranslate.nativeElement, {duration: 0, display: 'block'}, '');
      tl.to(this.actionTranslate.nativeElement, {duration: .2, opacity: 1}, '<');
    }
  }

  public setInputState(focus): void {
    this.translated = false;
    const d = 0.5;
    const tl = gsap.timeline();
    tl.to(this.layerResult.nativeElement, {duration: d, top: '100%'}, '');
    tl.to(this.layer.nativeElement, {duration: d, backgroundColor: this.white, color: this.black}, '<');
    tl.to(this.layerTextArea.nativeElement, {duration: d, height: '100%'}, '<');
    tl.to(this.switch.nativeElement, {duration: d, height: 'auto', paddingBottom: '14px'}, '<');
    if (focus) {
      tl.call(() => {
        this.setFocus()
      }, null, '>');
    }
  }

  public setActiveView(view) {
    //hide submit button (position: fixed)
    this.renderer.setStyle(this.actionTranslate.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.actionTranslate.nativeElement, 'opacity', 0);
    //set state to input after screen is slide out
    setTimeout(() => {
      this.setInputState(false);
      this.input.setValue('');
    }, 200);
    //set view
    this.setActiveViewOutput.emit(view);
  }

  public toggleSwitch() {
    console.log('this.isInputFocused', this.isInputFocused);
    const tl = gsap.timeline();
    tl.to('.switch__middle .icon img', {duration: 0.4, rotateY: this.rotateY + 'deg', transformOrigin: '50% 50%'});
    tl.call(() => {
      this.rotateY = this.rotateY === 0 ? 180 : 0;
    }, null, '<');
    tl.to('.switch__left', {duration: 0.2, opacity: 0}, '<');
    tl.to('.switch__right', {duration: 0.2, opacity: 0}, '<');
    tl.to('.switch__left', {duration: 0.2, opacity: 1}, '0.2');
    tl.to('.switch__right', {duration: 0.2, opacity: 1}, '0.2');
    tl.call(() => {
      this.appService.inputFry = !this.appService.inputFry;
    }, null, '0.2');
    tl.call(() => {
      this.layerTextArea.nativeElement.focus();
    }, null, '>');
  }

  public inputPaste() {
    Clipboard.read().then((result) => {
      this.input.setValue(result.value.substring(0, 10000));
      console.log('Got', result.type, 'from clipboard:', result.value);
      setTimeout(() => {
        this.setFocus()
      }, 0);
    });
  }

  public resultCopy() {
    Clipboard.write({
      string: this.resultsInput
    }).then((_) => {
      Toast.show({
        text: 'Do kinst it wurd \'' + this.resultsInput + '\' no yn oare apps plakke.',
        duration: 'long',
        position: 'top'
      });
      console.log('Write to clipboard:', this.resultsInput);
    });
  }
}
