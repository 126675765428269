import {Component, Input, OnChanges, OnInit} from '@angular/core';

@Component({
  selector: 'app-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss'],
})
export class AnswerComponent implements OnInit, OnChanges {
  @Input() answer: any;
  @Input() givenAnswer: boolean;
  public iconClass: any = {'icon--circle': true, 'color--green': true};

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    console.log('AnswerComponent ngOnChanges()', this.givenAnswer);
    if (this.givenAnswer) {
      this.iconClass = {'icon--check-circle': true, 'color--green4': true};
    } else {
      this.iconClass = {'icon--circle': true, 'color--green': true};
    }
  }
}
