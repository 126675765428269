import { Question, Answer } from '../quiz/quiz.interface';

export const QUIZ: Question[] = [
  {
    id: 1,
    content: 'Yn de bibel stiet dat Jezus ... nei de himel.',
    answers: [
      {
        id: 1,
        content: 'opfarde',
        correct: true,
      } as Answer,
      {
        id: 2,
        content: 'opfear',
        correct: false,
      } as Answer,
      {
        id: 3,
        content: 'opfearre',
        correct: false,
      } as Answer
    ]
  } as Question,
  {
    id: 2,
    content: 'Yn de bibel stiet dat Jezus ... nei de himel.',
    answers: [
      {
        id: 1,
        content: 'opfarde',
        correct: false,
      } as Answer,
      {
        id: 2,
        content: 'opfear',
        correct: true,
      } as Answer,
      {
        id: 3,
        content: 'opfearre',
        correct: false,
      } as Answer
      ]
  } as Question,
  {
    id: 3,
    content: 'Yn de bibel stiet dat Jezus ... nei de himel.',
    answers: [
      {
        id: 1,
        content: 'opfarde',
        correct: true,
      } as Answer,
      {
        id: 2,
        content: 'opfear',
        correct: false,
      } as Answer,
      {
        id: 3,
        content: 'opfearre',
        correct: false,
      } as Answer
    ]
  } as Question
];
