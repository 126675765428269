import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {GifsService} from './gifs.service';

@Component({
  selector: 'app-gifs',
  templateUrl: './gifs.component.html',
  styleUrls: ['./gifs.component.scss'],
})
export class GifsComponent implements OnInit, OnChanges {
  @Input() activeView: string;
  @Output() setActiveViewOutput = new EventEmitter();
  public gifs: any[];
  public gif: any;
  public sliderSlideActive = 'gifs';
  public loaderActive = true;

  constructor(public gifsService: GifsService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeView.currentValue === 'gifs') {
      this.loaderActive = true;
      console.log('changes.activeView.currentValue', changes.activeView.currentValue);
      this.gifsService.get().subscribe(data => {
        console.log('gifsService', data);
        this.gifs = data.data;
        setTimeout(() => {
          this.loaderActive = false;
        }, 1500);
      });
    }
  }

  public setActiveView(): void {
    if (this.sliderSlideActive === 'gifs') {
      this.setActiveViewOutput.emit('dashboard');
      setTimeout(() => {
        this.gifs = [];
        this.loaderActive = true;
      }, 200);
    } else {
      this.sliderSlideActive = 'gifs';
      setTimeout(() => this.gif = null, 200);
    }
  }

  public setActiveGif(gif): void {
    this.gif = gif;
    this.sliderSlideActive = 'gif';
  }
}
